module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__encoding@0._uyfrgqzfqnzxnh7yguf22epdkm/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-167628835-2"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-jss@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__encoding@0.1.13_rea_5xzpovvjycezawd3ceelw42ygm/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__encoding@0.1.13__kpdqodi6b77cx5wacp756v577a/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._yk3l43qzv6grjm5hxyunsn2sdq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
