import { Link } from 'gatsby'
import { merge } from 'lodash'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { CONTENT_TOP, useAppContext } from './layouts'

// constants

export const MEDIA = {
  mobile: '@media (max-width: 639px)',
  mobileTablet: '@media (max-width: 959px)',
  tablet: '@media (max-width: 959px) and (min-width: 640px)'
}

// functions

export const createUseClasses = (...classes) => createUseStyles(merge({}, ...classes))

export const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

// components

const ExternalLink = ({ children, mark }) =>
  mark.blank ? (
    <a href={mark.url} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <a href={mark.url}>{children}</a>
  )

const InternalLink = ({ children, mark }) => {
  const { setProjectPosition } = useAppContext()

  const handleClick = () => setProjectPosition({ left: -314, top: CONTENT_TOP })

  return mark.offscreen ? (
    <Link onClick={handleClick} to={mark.slug}>
      {children}
    </Link>
  ) : (
    <Link to={mark.slug}>{children}</Link>
  )
}

const Mailto = ({ children, mark }) => <a href={`mailto:${mark.email || 'info@iksdesign.jp'}`}>{children}</a>

// constants

export const SERIALIZERS = {
  marks: {
    externalLink: ExternalLink,
    internalLink: InternalLink,
    mailto: Mailto
  }
}
