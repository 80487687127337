import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { createUseClasses, SERIALIZERS } from '../common'
import { DATASET, PROJECT_ID, useAppContext } from '../layouts'

// hooks

const useClasses = createUseClasses({
  heading: { fontSize: 17, lineHeight: 1.2, margin: [8, 0, 7], opacity: 0.4 },
  headingDetail: { color: '#000', extend: 'heading' },
  inner: { '& a': { pointerEvents: 'auto' }, '& img': { height: 'auto', width: 290 }, padding: 12, pointerEvents: 'none', position: 'relative' },
  link: {
    '& + div figure': { transition: 'transform 0.1s ease-in-out' },
    borderRadius: 10,
    bottom: 0,
    cursor: 'pointer',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  linkCurrent: { '& + div figure': { transform: 'scale(0.995)' }, extend: 'link' },
  project: { '& figure': { height: 290, margin: 0 }, margin: [0, 3, 6], width: 314 },
  projectAfter: { extend: 'project', float: 'left', marginLeft: 0 },
  projectBefore: { extend: 'project', position: 'absolute', zIndex: 3 },
  projectDuring: { extend: 'project', position: 'absolute', transition: 'left 0.5s, top 0.5s', zIndex: 3 },
  summary: { '& a': { textDecoration: 'underline' } }
})

// components

const ConditionalLink = ({ currentLink, link, project, setCurrentLink }) => {
  const { setProjectPosition } = useAppContext()
  const classes = useClasses()

  const handleClick = ({ currentTarget }) => {
    const position = currentTarget.getBoundingClientRect()

    setProjectPosition({ left: position.left - 3, top: position.top })
  }

  const handleMouseOver = ({ currentTarget }) => setCurrentLink(currentTarget.href.match(/^https?:\/\/[^/]+(.*)/)[1])

  return link ? (
    <Link
      className={classes[project.slug.current === currentLink ? 'linkCurrent' : 'link']}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      to={project.slug.current}
    />
  ) : null
}

export const Project = ({ link, mounted, project, styles }) => {
  const { projectPosition } = useAppContext()
  const classes = useClasses()
  const [currentLink, setCurrentLink] = useState(null)

  const handleMouseLeave = () => setCurrentLink(null)

  const getProjectClass = () => {
    const after = !(projectPosition && window.matchMedia('(min-width: 640px)').matches)

    return classes[`project${link ? '' : after ? 'After' : mounted ? 'During' : 'Before'}`]
  }

  return (
    <div className={getProjectClass()} onMouseLeave={handleMouseLeave} style={styles}>
      <ConditionalLink currentLink={currentLink} link={link} project={project} setCurrentLink={setCurrentLink} />

      <div className={classes.inner}>
        <BlockContent blocks={project._rawThumbnailImage} dataset={DATASET} projectId={PROJECT_ID} serializers={SERIALIZERS} />

        <Title link={link} title={project.title} />

        <Summary summary={project._rawSummary} />
      </div>
    </div>
  )
}

const Summary = ({ summary }) => {
  const classes = useClasses()

  return summary ? (
    <div className={classes.summary}>
      <BlockContent blocks={summary} serializers={SERIALIZERS} />
    </div>
  ) : null
}

const Title = ({ link, title }) => {
  const classes = useClasses()

  return <h2 className={classes[link ? 'heading' : 'headingDetail']}>{title}</h2>
}
