import { useWindowSize } from '@react-hook/window-size'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import Masonry from 'react-masonry-component'
import { createUseClasses } from '../common'
import { Project } from './project'

// constants

const ALL_PROJECTS = graphql`
  query {
    allSanityProject(sort: { sequence: ASC }) {
      edges {
        node {
          _rawSummary
          _rawThumbnailImage
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const PATHNAME_REGEX = /^\/((blog\/)|(concept\/)|(contact\/)|(profile\/)|(recruit\/))?$/

// hooks

const useHooks = () => {
  const masonryRef = useRef()

  const { allSanityProject } = useStaticQuery(ALL_PROJECTS)

  const [projects, setProjects] = useState(allSanityProject.edges)

  return { masonryRef, projects, setProjects }
}

const useClasses = createUseClasses({
  projects: { marginBottom: 26, maxWidth: 320, minWidth: 320 },
  projectsMounted: { extend: 'projects', margin: [0, 'auto', 26], maxWidth: '100%' }
})

// components

export const Projects = ({ pathname, skip }) => {
  const classes = useClasses()
  const { masonryRef, projects, setProjects } = useHooks()
  const [mounted, setMounted] = useState(classes.projects)
  const size = useWindowSize()

  useEffect(() => masonryRef.current?.masonry.layout(), [masonryRef, size])

  useEffect(() => {
    const project = projects.findIndex(edge => edge.node.slug.current === pathname)

    if (project !== -1 && project !== projects.length - 1) {
      setProjects(projects.filter((_, index) => index !== project).concat(projects[project]))
    }

    setMounted(classes.projectsMounted)
  }, [classes.projectsMounted, pathname, projects, setProjects])

  if (skip) return null

  const updatedProjects = pathname.match(PATHNAME_REGEX) ? projects : projects.slice(0, -1)

  return (
    <Masonry className={mounted} ref={masonryRef}>
      {updatedProjects.map(({ node: project }) => (
        <Project key={project.title} link={true} project={project} />
      ))}
    </Masonry>
  )
}
