import '@fontsource/noto-serif-jp'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Menu, MenuItem } from '@mui/material'
import { useWindowWidth } from '@react-hook/window-size'
import { Link } from 'gatsby'
import { lowerCase } from 'lodash'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { createUseClasses, MEDIA } from '../common'
import { Projects } from '../components/projects'
import { jssSize } from '../jss'

//  constants

export const CONTENT_TOP = 204
export const DATASET = 'live'

const EXTERNAL_LINKS = [
  {
    class: 'Facebook',
    url: 'https://www.facebook.com/iksdesign'
  },
  {
    class: 'Instagram',
    url: 'https://www.instagram.com/i.k.sdesign/'
  },
  {
    class: 'Linkedin',
    url: 'https://www.linkedin.com/in/iksdesign/'
  },
  {
    class: 'X',
    url: 'https://x.com/gdkf51'
  }
]

export const HOME = '/'

const INTERNAL_LINKS = [
  {
    name: 'Concept',
    slug: '/concept/'
  },
  {
    name: 'Profile',
    slug: '/profile/'
  },
  {
    name: 'Blog',
    slug: '/blog/'
  },
  {
    name: 'Contact',
    slug: '/contact/'
  },
  {
    name: 'Recruit',
    slug: '/recruit/'
  }
]

const KEYWORDS = '京都'
const LOGO = <img alt="" height="148" src="/logo.png" width="100" />
export const PROJECT_ID = 'mjneo8p9'
export const PROPERTY = 'i.k.s'

// context

const APP_CONTEXT = createContext()

//  hooks

export const useAppContext = () => {
  const context = useContext(APP_CONTEXT)

  if (context === undefined) {
    throw new Error('Context error.')
  }

  return context
}

const useClasses = createUseClasses({
  all: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: [0, 'auto'],
    minHeight: '100vh',
    padding: [32, 0],
    transition: 'width 0.5s',
    width: 960
  },
  external: {
    ...jssSize(14),
    '&:hover': { opacity: 1 },
    backgroundSize: [14, 14],
    display: 'inline-block',
    marginLeft: 16,
    opacity: 0.3,
    transition: 'opacity 0.1s'
  },
  externalFacebook: { backgroundImage: 'url(/facebook.png)', extend: 'external' },
  externalInstagram: { backgroundImage: 'url(/instagram.png)', extend: 'external' },
  externalLinkedin: { backgroundImage: 'url(/linkedin.png)', extend: 'external' },
  externalX: { backgroundImage: 'url(/x.png)', extend: 'external' },
  footer: { marginLeft: 16, opacity: 0.4 },
  header: { alignItems: 'flex-end', display: 'flex', justifyContent: 'space-between', marginBottom: 24, transition: 'opacity 1.5s', whiteSpace: 'nowrap' },
  headerInitial: { extend: 'header', opacity: 0 },
  icon: { minWidth: 'auto !important', opacity: 0.4, padding: '0 !important', position: 'relative', top: 7 },
  internal: { '&:hover': { opacity: 1 }, fontSize: 14, letterSpacing: 2, marginLeft: 16, opacity: 0.4, textTransform: 'uppercase', transition: 'opacity 0.1s' },
  internalActive: { extend: 'internal', opacity: [1, '!important'] },
  link: { lineHeight: 0, marginBottom: 0 },
  links: { alignItems: 'center', display: 'flex', listStyleType: 'none', marginRight: 15, textAlign: 'right' },
  logo: { bottom: 2, marginLeft: 9, position: 'relative' },
  [MEDIA.mobile]: {
    all: { width: 320 },
    footer: { fontSize: 12, lineHeight: 1.2 },
    icon: { right: 12 }
  },

  [MEDIA.mobileTablet]: {
    internal: { marginLeft: 0 },
    internalActive: { marginLeft: 0 }
  },

  [MEDIA.tablet]: {
    all: { width: 640 },
    icon: { right: 12 }
  },

  menu: { '& .MuiMenuItem-root': { fontFamily: 'Noto Serif JP' } }
})

// components

export const Head = ({ canonical = HOME, title = KEYWORDS }) => (
  <Helmet>
    <link href={`https://iksdesign.jp${canonical}`} rel="canonical" />

    <title>{`${PROPERTY} | ${lowerCase(title)}`}</title>
  </Helmet>
)

const Layout = ({ children, location: { pathname }, pageContext: page }) => {
  const classes = useClasses()
  const [initial, setInitial] = useState(true)
  const [projectPosition, setProjectPosition] = useState()

  const appContext = useMemo(() => ({ projectPosition, setProjectPosition }), [projectPosition, setProjectPosition])

  useEffect(() => setInitial(false), [])

  return (
    <APP_CONTEXT.Provider value={appContext}>
      <div className={classes.all} id="all">
        <div>
          <header className={classes[initial ? 'headerInitial' : 'header']}>
            <Logo path={pathname} />

            <Links path={pathname} type={page._type} />
          </header>

          {children}

          <Projects pathname={pathname} skip={page._type === 'page' || page._type === 'post'} />
        </div>

        <footer className={classes.footer}>Copyright © 2023 i.k.s design. All rights reserved.</footer>
      </div>
    </APP_CONTEXT.Provider>
  )
}

const Links = ({ path, type }) => {
  const classes = useClasses()
  const width = useWindowWidth()

  if (width < 960) {
    return <MobileLinks path={path} type={type} />
  }

  return (
    <ul className={classes.links}>
      <li className={classes.link}>
        <Link to="/">
          <span className={classes[path === '/' || type === 'project' ? 'internalActive' : 'internal']}>Work</span>
        </Link>
      </li>

      {INTERNAL_LINKS.map(link => (
        <li className={classes.link} key={link.slug}>
          <Link to={link.slug}>
            <span className={classes[path.match(link.slug) ? 'internalActive' : 'internal']}>{link.name}</span>
          </Link>
        </li>
      ))}

      {EXTERNAL_LINKS.map(link => (
        <li className={classes.link} key={link.class}>
          <a href={link.url} rel="noopener noreferrer" target="_blank">
            <span className={classes[`external${link.class}`]} />
          </a>
        </li>
      ))}
    </ul>
  )
}

const Logo = ({ path }) => {
  const classes = useClasses()

  return path === HOME ? (
    <h1 className={classes.logo}>{LOGO}</h1>
  ) : (
    <Link className={classes.logo} to={HOME}>
      <h1>{LOGO}</h1>
    </Link>
  )
}

const MobileLinks = ({ path, type }) => {
  const classes = useClasses()
  const [anchor, setAnchor] = useState(null)

  const handleClick = event => setAnchor(event.currentTarget)

  const handleClose = () => setAnchor(null)

  return (
    <>
      <Button className={classes.icon} onClick={handleClick}>
        <MenuIcon />
      </Button>

      <Menu anchorEl={anchor} classes={{ list: classes.menu }} onClose={handleClose} open={Boolean(anchor)}>
        <MenuItem onClick={handleClose}>
          <Link to="/">
            <span className={classes[path === '/' || type === 'project' ? 'internalActive' : 'internal']}>Work</span>
          </Link>
        </MenuItem>

        {INTERNAL_LINKS.map(link => (
          <MenuItem key={link.slug} onClick={handleClose}>
            <Link to={link.slug}>
              <span className={classes[path.match(link.slug) ? 'internalActive' : 'internal']}>{link.name}</span>
            </Link>
          </MenuItem>
        ))}

        {EXTERNAL_LINKS.map(link => (
          <MenuItem key={link.class} onClick={handleClose}>
            <a className={classes.internal} href={link.url}>
              {link.class}
            </a>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Layout
